import { format } from "date-fns";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import orderedImage from "../assets/carousal_assets/3.jpg";
import "./orderTracker.css";
import { getOrderTrackingInfo } from "../api/index.js";
import { useLocation } from 'react-router-dom';

export default function OrderTracker() {

  const [product, getProduct] = useState({});
  const [statusList, setStatusList] = useState([]);
  const location = useLocation();
  const state = location.state || {};
 
  useEffect(() => {
    loadOrder()
  }, [])

  const loadOrder = async () => {
    let orderList = await getOrderTrackingInfo({
      orderProductId:state?.id,
      billId:state?.billId
    });
    getProduct(orderList?.cartOrderProduct);
    setStatusList(orderList?.statusList);
  }

  return (
    <>
      <div class="container py-5">
        <div class="row flex-xs-column-reverse flex-sm-column-reverse flex-lg-row-reverse flex-md-row-reverse row-gap-5 for-mob-product">
          <div class="col-md-6 col-lg-6">
            <div id="tracking-pre"></div>
            <div id="tracking">
              <div class="tracking-list rounded-2">
                {
                  statusList?.map((res)=>
                    <div>
                      {
                        
                        <div class={`tracking-item${res?.date === null ? '-pending' : '' }`}>
                    <div class="tracking-icon status-intransit">
                      <svg class="svg-inline--fa fa-circle fa-w-16" aria-hidden="true" data-prefix="fas" data-icon="circle" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg="">
                        <path fill="currentColor" d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8z"></path>
                      </svg>
                    </div>
                    <div class="tracking-date"><img src="https://raw.githubusercontent.com/shajo/portfolio/a02c5579c3ebe185bb1fc085909c582bf5fad802/delivery.svg" class="img-responsive" alt="order-placed" /></div>
                    <div class="tracking-content">{res?.status}
                      {/* <span>{ res?.date !== null ? format(res?.time?.toString() , 'dd MMM yyyy hh:mm a'): ''}</span> */}
                      </div>
                  </div> 
                      }
                  </div>
                  )
                }
              </div>
            </div>
          </div>
          <div class="col-md-6 col-lg-6">
            <div className="card card-product">
              <div className="card-body">
                <div className="text-center position-relative">
                  <Link to={'/'}>
                    <img
                      src={product?.product?.image}
                      alt={''}
                      className="img-fluid pt-5"
                    />
                  </Link>
                </div>
                <div className="text-small mb-1">
                  <Link
                    to={`/`}
                    className="text-decoration-none text-muted"
                  >
                    <small>{product?.product?.slug}</small>
                  </Link>
                </div>
                <h2 className="fs-6">
                  <Link
                    to={`/`}
                    className="text-inherit text-decoration-none"
                  >
                    {product?.product?.title}
                  </Link>
                </h2>

                <div className="d-flex justify-content-between align-items-center mt-3">
                  <div>
                    <span className="text-dark">{product?.total_amount}</span>
                    {/* {
                product?.product_variant?.offer && (
                  <span className="text-decoration-line-through text-muted">
                    ₹499
                  </span>
                )
              } */}
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </>
  );
}