import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { getUserOrders } from "../api/index.js";
import { Pagination } from "../common-component/pagination.js";
import { format } from "date-fns";
import empty_order from "../assets/empty_orders.png";

export const AccountOrder = () => {
  const [orderedProducts, setAccountOrderedProducts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [totalContentSize, setTotalContentSize] = useState(0);
  const [loadFlag, setLoadFlag] = useState(false);
  const navigate = useNavigate();

  // useEffect(() => {
  //   const loadAddressList = () => {
  //     getUserOrders().then((re) => {
  //       setAccountOrderedProducts(re?.cartOrderProducts);
  //       setTotalContentSize(re?.cartOrderCount);
  //     });
  //   };
  //   loadAddressList();
  // }, []);

  const loadOrder = async () => {
    const skip = pageSize * (currentPage - 1)
    const limit = pageSize;
    let orderList = await getUserOrders({ skip, limit });
    if (orderList) {
      setAccountOrderedProducts(orderList?.cartOrderProducts);
      setTotalContentSize(orderList?.cartOrderCount);
    } else {
      setAccountOrderedProducts(0);
      setTotalContentSize(0);
    }
  }

  const toTracker = (event, orderedProduct) => {
    navigate('/track', { 
      state: { id : orderedProduct?.id, billId : orderedProduct?.order_bill_id },
      replace: true // Replaces the current entry in the history stack
    });
  }

  useEffect(() => {
    loadOrder();
    // getUserOrders({ skip, limit }).then((re) => {
    //   setAccountOrderedProducts(re?.cartOrderProducts);
    //   setTotalContentSize(re?.cartOrderCount);
    // });
  }, [currentPage]);

  return (
    <>
      <section>
        {
          orderedProducts?.length > 0 ?

            <><h2 className="mb-6">Your Orders</h2>
              <div className="table-responsive border-0 text-center">
                {/* Table */}
                <table className="table mb-0">
                  {/* Table Head */}
                  <thead className="bg-light">
                    <tr>
                      <th>&nbsp;</th>
                      <th>Product</th>
                      <th>Order</th>
                      <th>Date</th>
                      <th>Items</th>
                      <th>Status</th>
                      <th>Amount</th>
                      <th>Action</th>
                      <th />
                    </tr>
                  </thead>
                  <tbody>
                    {orderedProducts?.map((orderedProduct) => (
                      <tr>
                        <td className="align-middle border-top-0 w-0">
                          <Link to={`/product-detail/${orderedProduct?.product?.slug}`}>
                            <img
                              src={orderedProduct?.image}
                              alt={orderedProduct?.product?.title}
                              className="icon-shape icon-xl"
                            />
                          </Link>
                        </td>
                        <td className="align-middle border-top-0">
                          <Link to={`/product-detail/${orderedProduct?.product?.slug}`} className="fw-semibold text-inherit">
                            <h6 className="mb-0">{orderedProduct?.product?.title}</h6>
                          </Link>
                          <span>
                            <small className="text-muted">{orderedProduct?.product_variant?.attribute_value?.title}</small>
                          </span>
                        </td>
                        <td className="align-middle border-top-0">
                          <a className="text-inherit">
                            #{orderedProduct?.order_number}
                          </a>
                        </td>
                        <td className="align-middle border-top-0">{format(orderedProduct?.created_at, 'dd-MM-yyyy')}</td>
                        <td className="align-middle border-top-0">{orderedProduct?.quantity}</td>
                        <td className="align-middle border-top-0">
                          <span className="badge bg-warning">{orderedProduct?.status === null ? 'Waiting for Confirmation' : orderedProduct?.status}</span>
                        </td>
                        <td className="align-middle border-top-0">{orderedProduct?.total_amount}</td>
                        <td className="align-middle border-top-0">
                          {/* <Link to={`/product-detail/${orderedProduct?.product?.slug}?order_review=${orderedProduct?.id}&date=${format(orderedProduct?.created_at, 'dd-MM-yyyy')}`} className="fw-semibold text-inherit" >
                            Review
                          </Link> */}
                          <button to={`track`} className="fw-semibold text-inherit bg-white border-0" onClick={(event)=>toTracker(event,orderedProduct)}>
                            Track
                          </button>
                        </td>
                        {/* <td className="text-muted align-middle border-top-0">
                  <a
                    href="#"
                    className="text-inherit"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    data-bs-title="View"
                  >
                    <i className="feather-icon icon-eye" />
                  </a>
                </td> */}
                      </tr>
                    ))}
                  </tbody>
                </table>
                {
                  totalContentSize > pageSize ? (
                    <div className="row mt-8">
                      <div className="col">
                        <Pagination currentPage={currentPage} setCurrentPage={setCurrentPage} totalContentSize={totalContentSize} pageSize={pageSize} />
                      </div>
                    </div>
                  ) : null
                }
              </div>
            </>

            : 

            <div className="text-center p-4">
              <img alt="" src={empty_order} className="w-20 h-20"></img>
              <h3 className="pt-7">You have no orders Right Now..!</h3>
            </div>

        }
      </section>
    </>
  );
};
