import { useEffect, useRef, useState } from "react";
import { loadProfile, updatePasswordDetails, updateProfile, sendMobileVerification, otpVerification } from '../api/index';
import tickmarkIcon from '../assets/tick_mark.png';
import notverified_tickmarkIcon from '../assets/tick_mark_not_verified.png';
import { intervalToDuration, minutesToSeconds } from 'date-fns';

export const AccountSetting = () => {
  // fade yet to add 
  const [personalDetails, setPersonalDetails] = useState({
    name: '',
    email: '',
    mobile: '',
    mailOTP: '',
    mobileOTP: '',
    mobileVerified: null
  });
  const [passwordDetails, setPasswordDetails] = useState({
    old_password: '',
    new_password: '',
    confirm_password: ''
  });
  const [oldPwdToggler, setOldPwdToggler] = useState(false);
  const [newPwdToggler, setNewPwdToggler] = useState(false);
  const [confPwdToggler, setConfPwdToggler] = useState(false);
  const [toasterContent, setToasterContent] = useState("");
  const timerRef = useRef(0);
  const otpTimerRef = useRef(0);
  const [timerValue, setTimerValue] = useState(5);
  const [profileDisableFlag, setProfileDisableFlag] = useState(false);
  const [passwordDisableFlag, setPasswordDisableFlag] = useState(false);
  const [numberVerifyFlag, setNumberVerifyFlag] = useState(false);
  const [otpTriigerFlag, setOtpTriggerFlag] = useState(false);
  const [buttonEnableFlag, setButtonEnableFlag] = useState(false);
  // const [otpTimer, setOtpTimer] = useState();
  const [otpNumber, setOtpNumber] = useState('');
  const [timerFormat, setTimerFormat] = useState('');
  const [otpTimerValue, setOtpTimerValue] = useState();

  const timerFunction = () => {
    if (timerRef.current) {
      return;
    }
    timerRef.current = setInterval(() => setTimerValue((re) => re - 1), 1000);
  }

  const toasterHideandShow = () => {
    if (document.getElementsByClassName('toast')[0].className.includes('show')) {
      document.getElementsByClassName('toast')[0].classList.add('hide');
      document.getElementsByClassName('toast')[0].classList.remove('show');
    } else {
      document.getElementsByClassName('toast')[0].classList.add('show');
      document.getElementsByClassName('toast')[0].classList.remove('hide');
    }
  }


  const updateDetails = (event) => setPersonalDetails({ ...personalDetails, [event.target.name]: event.target.value })

  const toasterFunctionHandler = (functionFlag) => {
    timerFunction();
    toasterHideandShow();
    if (functionFlag === 'profileUpdate') {
      setProfileDisableFlag(true);
    } else {
      setPasswordDisableFlag(true);
    }
    setTimeout(() => {
      if (functionFlag === 'profileUpdate') {
        setProfileDisableFlag(false);
      } else {
        setPasswordDisableFlag(false);
      }
      toasterHideandShow();
      setTimerValue(5);
      clearInterval(timerRef.current);
      timerRef.current = 0;
    }, 5000);
  }

  const saveDetails = (event) => {
    event.preventDefault();
    if ((personalDetails.name !== '' || personalDetails.name !== undefined) && (personalDetails.email !== '' || personalDetails.email !== undefined) && (personalDetails.mobile !== '' || personalDetails.mobile !== undefined)) {
      updateProfile(personalDetails).then((res) => {
        if (res?.code === "200") {
          setToasterContent(res?.message);
          toasterFunctionHandler('profileUpdate');
        } else {
          setToasterContent(res?.error[0]?.message);
          toasterFunctionHandler('profileUpdate');
        }
      });
    } else {
      setToasterContent('Details are not enough to update');
      toasterFunctionHandler('profileUpdate');
    }
  }

  const changePassword = (event) => setPasswordDetails({ ...passwordDetails, [event.target.name]: event.target.value })

  const updatePassword = (event) => {
    event.preventDefault();
    if (passwordDetails.old_password !== '' && passwordDetails.new_password !== '' && passwordDetails.confirm_password !== '') {
      updatePasswordDetails(passwordDetails).then((res) => {
        if (res?.code === "200") {
          setToasterContent(res?.message);
          toasterFunctionHandler('passwordUpdate');
        } else {
          setToasterContent(res?.message || res.response.data.error[0].message);
          toasterFunctionHandler('passwordUpdate');
        }
      });
    } else {
      setToasterContent('Details are not enough to update');
      toasterFunctionHandler('passwordUpdate');
    }
  }

  const oldPasswordToggle = () => setOldPwdToggler(!oldPwdToggler)
  const newPasswordToggle = () => setNewPwdToggler(!newPwdToggler);
  const currentPasswordToggle = () => setConfPwdToggler(!confPwdToggler);

  const loadProfileData = () => {
    loadProfile().then((res) => {
      setPersonalDetails({
        name: res?.data?.customer?.name,
        email: res?.data?.customer?.email,
        mobile: res?.data?.customer?.mobile,
        mobileVerified: res?.data?.customer?.mobile_verified_at
      });
      setNumberVerifyFlag(res?.data?.customer?.mobile_verified_at !== null ? true : false);
    });
  }

  useEffect(() => {
    loadProfileData();
  }, []);

  const verifyMobile = (e) => {
    toasterHideandShow();
    timerFunction();
    if(otpNumber.length === 6 ){
      let params = {
        mobile : personalDetails.mobile,
        otp : otpNumber
      }
      const verifyOtp = otpVerification(params);
      verifyOtp.then((re)=>{
        if(re.code === "200" && re?.message === "Mobile Verified Successfully!!"){
          setToasterContent("Your OTP verified Successfully..!");
          setOtpTriggerFlag(false);
          setNumberVerifyFlag(true);
          setOtpNumber('');
          setTimeout(() => {
            toasterHideandShow();
            setTimerValue(5);
            clearInterval(timerRef.current);
            timerRef.current = 0;
          }, 5000);
        }else if(re?.message === "Please Enter Valid OTP"){
          setToasterContent(re?.message);
          setTimeout(() => {
            toasterHideandShow();
            setTimerValue(5);
            clearInterval(timerRef.current);
            timerRef.current = 0;
          }, 5000);
        }
      })
    }else{
      setToasterContent("Please enter the correct OTP..!");
      setTimeout(() => {
        toasterHideandShow();
        setTimerValue(5);
        clearInterval(timerRef.current);
        timerRef.current = 0;
      }, 5000);
    }
    clearTimeout(setButtonEnableFlag(false));
    e.preventDefault();
  }

  useEffect(() => {
    if(otpTimerValue !== undefined){
      const otpTimerFunction = () => {
        if (otpTimerRef.current) {
          return;
        }
        otpTimerRef.current = setInterval(() => setOtpTimerValue((re) => re - 1), 1000);
      }
      otpTimerFunction();
      const duration = intervalToDuration({ start: 0, end: otpTimerValue * 1000 })
      const zeroPad = (num) => String(num).padStart(2, '0')
      const formatted = `${zeroPad(duration.minutes)}:${zeroPad(duration.seconds)}`;
      setTimerFormat(formatted);
    }else{
      return;
    }
  }, [otpTimerValue]);

  const otpTimer = () => {
    setOtpTimerValue(299);
  }

  const verifyMobileNumber = (e) => {    
    // axios.post(`send-email-verification`,{
    //   email : 'kkigoapparels@gmail.com'
    // });
    if(personalDetails.mobile.length === 10){
      const getOtpRequest = sendMobileVerification({ mobile: personalDetails.mobile });
      getOtpRequest.then((re)=> {
        if(re?.code === "200" && re?.message === "SMS Send Successfully"){
          setTimerValue(10000);
          setOtpTriggerFlag(true);
          setButtonEnableFlag(true);
          setTimeout(()=>{
            setButtonEnableFlag(false);
          },15000);
        }else if( re?.code === "422" && re?.message === "Customer Mobile Already Verified Successfully"){
          otpTimer();
          // toasterHideandShow();
          // timerFunction();
          // setToasterContent(re?.message);
          // setTimeout(() => {
          //   toasterHideandShow();
          //   setTimerValue(5);
          //   clearInterval(timerRef.current);
          //   timerRef.current = 0;
          // }, 5000);
        }
      });
    }else{
      toasterHideandShow();
      timerFunction();
      setToasterContent("Please enter correct Mobile number..!");
      setTimeout(() => {
        toasterHideandShow();
        setTimerValue(5);
        clearInterval(timerRef.current);
        timerRef.current = 0;
      }, 5000);
    }
    e.preventDefault();
  }

  const changeOtp = (e) => {
    setOtpNumber(e.target.value);
  }

  // const OTPTrigger = (e) => {
  //   e.preventDefault();
  // }


  return (
    <>
      <div className="mb-6">
        {/* heading */}
        <h2 className="mb-0">Account Settings</h2>
      </div>
      <div>
        {/* heading */}
        <h5 className="mb-4">Account details</h5>
        <div className="row">
          <div className="col-lg-7">
            {/* form */}
            <form >
              {/* input */}
              <div className="mb-3">
                <label className="form-label">Name</label>
                <input type="text" className="form-control" placeholder="Name" name="name" value={personalDetails.name} onChange={updateDetails} />
              </div>
              {/* input */}
              <div className="mb-3">
                <label className="form-label">Email</label>
                <div className="gap-2">
                  <div className="d-flex align-items-center flex-row-reverse">
                  <input type="email" className="form-control" placeholder="example@gmail.com" name="email" value={personalDetails.email} onChange={updateDetails} />
                  <img src={tickmarkIcon} alt="" className="position-absolute me-3" title="verified"></img>
                    
                    </div>
                  {/* <span className="col-lg-2">{ numberVerifyFlag ? "Verified" : "Not Verified"}</span> */}
                  {/* <button className="btn text-bg-info mt-3" disabled={buttonEnableFlag} onClick={verifyMail}>{numberVerifyFlag ? "Change": "Get OTP"}</button> */}
                </div>
                {/* { otpTriigerFlag && <div className="d-flex align-items-center gap-2 mt-4">
                <input type="number" className="form-control col-lg-4 w-25" placeholder="xxxxxx" name="mailOTP" value={personalDetails.mailOTP} onChange={updateDetails}/>
                <button className="btn text-bg-info col-lg-2" onClick={OTPTrigger}>{numberVerifyFlag ? "Change": "Verify"}</button>
                </div>} */}
              </div>
              {/* input */}
              <div className="mb-5">
                <label className="form-label">Phone</label>
                <div className="gap-2">
                <div className="d-flex align-items-center flex-row-reverse">
                  <input type="number" className="form-control" placeholder="Phone number" name="mobile" value={personalDetails.mobile} onChange={updateDetails} />
                  {numberVerifyFlag ? <img src={tickmarkIcon} alt="" className="position-absolute me-3" title="verified"></img>
                    : <img src={notverified_tickmarkIcon} alt="" className="opacity-50 position-absolute me-3" title="Not verified"></img>}
                    </div>
                    {/* <span className="col-lg-2">{ numberVerifyFlag ? "Verified" : "Not Verified"}</span> */}
                    <button className="btn text-bg-info mt-3" disabled={buttonEnableFlag} onClick={verifyMobileNumber}>Get OTP</button>
                    <span>{timerFormat}</span>
                    
                  {otpTriigerFlag && <div className="d-flex align-items-center gap-2 mt-4">
                    <input type="number" className="form-control col-lg-4 w-25" placeholder="xxxxxx" name="mobileOTP" value={personalDetails.mobileOTP} onChange={changeOtp} />
                    <button className="btn text-bg-info col-lg-2" onClick={verifyMobile}>Verify</button>
                  </div>}
                </div>
              </div>
              {/* button */}
              {/* <div className="mb-3">
                <button className="btn btn-primary text-bg-dark" onClick={saveDetails} disabled={profileDisableFlag} id="liveToastBtn">Save</button>
              </div> */}
            </form>
          </div>
        </div>
      </div>
      <hr className="my-10" />
      <div className="pe-lg-14">
        {/* heading */}
        <h5 className="mb-4">Password</h5>
        <div className="row">
          <div className="col-lg-5">
            <form>
              {/* input */}
              <div className="mb-3 col password-field position-relative">
                <label className="form-label">Current Password</label>
                <input type={oldPwdToggler ? 'text' : 'password'} className="form-control" placeholder="**********" value={passwordDetails.old_password} onChange={changePassword} name="old_password" />
                <span>
                  <i className={oldPwdToggler ? 'bi bi-eye passwordToggler' : 'bi bi-eye-slash passwordToggler'} style={{ transform: 'translateY(40%)', right: '30px' }} onClick={oldPasswordToggle} />
                </span>
              </div>
              {/* input */}
              <div className="mb-3 col password-field position-relative">
                <label className="form-label">New Password</label>
                <input type={newPwdToggler ? 'text' : 'password'} className="form-control" placeholder="**********" value={passwordDetails.new_password} onChange={changePassword} name="new_password" />
                <span>
                  <i className={newPwdToggler ? 'bi bi-eye passwordToggler' : 'bi bi-eye-slash passwordToggler'} style={{ transform: 'translateY(40%)', right: '30px' }} onClick={newPasswordToggle} />
                </span>
              </div>
              {/* input */}
              <div className="mb-3 col password-field position-relative">
                <label className="form-label">Confirm Password</label>
                <input type={confPwdToggler ? 'text' : 'password'} className="form-control" placeholder="**********" value={passwordDetails.confirm_password} onChange={changePassword} name="confirm_password" />
                <span>
                  <i className={confPwdToggler ? 'bi bi-eye passwordToggler' : 'bi bi-eye-slash passwordToggler'} style={{ transform: 'translateY(40%)', right: '30px' }} onClick={currentPasswordToggle} />
                </span>
              </div>
              {/* input */}
              <div className="col-12">
                {/* <p className="mb-4">
              Can’t remember your current password?
              <Link to="/forgot-password" className="text-dark"> Reset your password.</Link>
            </p> */}
                <button className="btn btn-primary text-bg-dark" onClick={updatePassword} disabled={passwordDisableFlag}>Change Password</button>
              </div>
            </form>
          </div>
        </div>
      </div>
      {/* <hr className="my-10" /> */}
      {/* <div>
        <h5 className="mb-4">Delete Account</h5>
        <p className="mb-2">Would you like to delete your account?</p>
        <p className="mb-5">This account contain 12 orders, Deleting your account will remove all the order details associated with it.</p>
        <a href="#" className="btn btn-outline-danger">I want to delete my account</a>
      </div> */}

      {/* toaster message */}

      <div className="col-12 position-fixed top-0 end-0 p-3" style={{ zIndex: 11 }}>
        <div id="liveToast" className="float-end toast hide" role="alert" aria-live="assertive" aria-atomic="true">
          <div className="toast-header">
            {/* <img src="..." className="rounded me-2" alt="..."/> */}
            <strong className="me-auto">Status Message</strong>
            <small>Will disappear in {timerValue} seconds</small>
            {/* <button type="button" className="btn-close" data-bs-dismiss="toast" aria-label="Close"></button> */}
          </div>
          <div className="toast-body">
            {toasterContent}
          </div>
        </div>
      </div>

    </>
  )
}